import { IoCall, IoMail, IoMap } from 'react-icons/io5';
import {
  ScColumnsResponsive,
  ScFlexAlignCenter,
  ScHeader3,
  ScImage,
  ScMaxWidthContainer,
  ScMaxWidthContainerWide,
} from './../components/styled-components';

import Image from '../components/image';
import Layout from '../components/layout';
import React from 'react';
import SEO from '../components/seo';
import globals from '../utils/variables';
import styled from 'styled-components';

const ScBlackContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Balance & Effizienz im Alltag" keywords={globals.keywords} />
    <ScBlackContainer>
      <ScMaxWidthContainer>
        <ScColumnsResponsive>
          <div style={{ flex: '1', paddingLeft: '1rem' }}>
            <ScHeader3>NADA-Akupunktur</ScHeader3>
            <p>
              NADA-Akupunktur ist eine spezielle Ohr-Akupunktur, die bei
              Dysbalance und psychischem Stress ihre Anwendung findet. Es kommt
              dadurch zur Entspannung und inneren Ruhe, weiters zu einer
              Verringerung der Ängstlichkeit. Der Schlafrhythmus wird
              verbessert, die Konzentrationsfähigkeit gesteigert und die
              Endorphin-Produktion angeregt. NADA-Akupunktur sorgt für
              Ausgeglichenheit und innere Stärkung.
            </p>
            <p>
              <strong>Anwendung</strong>:
            </p>
            <ul>
              <li>
                für Menschen, die ein Abhängigkeitsproblem (Alkohol, Drogen,
                Medikamente, Nikotin) überwinden wollen
              </li>
              <li>
                für Menschen, die unter Druck stehen, an innerer Unruhe,
                Schlafstörungen leiden
              </li>
              <li> für Menschen nach traumatischen Erlebnissen</li>
            </ul>
            <p>
              Die NADA-Therapie kann Sie stärker, stabiler und sicherer machen.{' '}
              <br />
              Sie ist eine komplementäre Behandlungsmethode.
            </p>
          </div>
          <ScImage
            style={{
              width: '30rem',
              height: '23rem',
              margin: '1rem',
            }}
            src="pictures/water-lily.jpg"
          />
        </ScColumnsResponsive>
      </ScMaxWidthContainer>
    </ScBlackContainer>
  </Layout>
);

export default IndexPage;
